import { useContext, useEffect, useState } from "react";
import { EventProperties, sendEventByOptions } from "../services/amplitudeClient";
import getAccessToken from "../util/getAccessToken";
import { AmplitudeEventType, ClientConfigType } from "../../types/clientConfigTypes";
import { ClientConfigContext } from "../clientContext";
import { ApiRequestLogger } from "../logger/apiRequestLogger";
import { useMsal } from "@azure/msal-react";
import { useBrand } from "./useBrand";

type UseAnalyticsOptionsWithEventSource = {
    eventSource: string;
    useJWT?: boolean;
}

type UseAnalyticsOptionsWithLogger = {
    logger: ApiRequestLogger;
    useJWT?: boolean;
}

export type UseAnalyticsOptions = UseAnalyticsOptionsWithEventSource | UseAnalyticsOptionsWithLogger;

export type UseAnalyticsResult = {
    sendEvent: (amplitudeEventType: AmplitudeEventType, eventProperties?: EventProperties) => void;
    eventProperties: EventProperties,
    setEventProperties: (eventProperties: EventProperties) => void;
};

const useAnalyticsDefaultOptions: UseAnalyticsOptions = {
    eventSource: 'Unknown',
    useJWT: true
}

const getSiteProperties = (brand: string) => {
    const searchParams = new URLSearchParams(document.location.search);
    const isMobile = searchParams.get('deviceType') === 'true';
    const siteDomain = isMobile ? `cep_${brand}` : window.location.hostname;
    const siteUrl = isMobile ? "" : window.location.hostname + window.location.pathname;
    return {
        site_domain: siteDomain,
        site_url: siteUrl,
        viewport: window.innerWidth + "x" + window.innerHeight
    }
}

export const useAnalytics = ( options: UseAnalyticsOptions ): UseAnalyticsResult => {
    const brand = useBrand();
    const config = {...useAnalyticsDefaultOptions, ...options};
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const {instance, accounts} = useMsal();
    const [jwt, setJwt] = useState("");
    const [eventProperties, setEventProperties] = useState<EventProperties>(getSiteProperties(brand.brand));
 
    useEffect(() => {
    const maxMessageLength = clientConfiguration?.loggingConfig?.maxMessageLength; 
        const logger = 'logger' in config ? config.logger : new ApiRequestLogger({ eventSource: config.eventSource }, maxMessageLength);
        if (config.useJWT) { 
            getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, logger).then((jwt) =>{
                setJwt(jwt);
            });
        }
    }, []);
    
    return {
        sendEvent: (amplitudeEventType: AmplitudeEventType, eventProperties?: EventProperties) => sendEventByOptions({
            eventProperties,
            eventType: amplitudeEventType, 
            jwt
        }),
        eventProperties,
        setEventProperties
    }
}
