import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useHistory, useLocation} from 'react-router-dom';
import {Grid, Typography} from '@material-ui/core';
import {useMsal} from "@azure/msal-react";

import getAccessToken from "../../../../util/getAccessToken";
import Button from '../../../elements/Button';
import BrandedCard from '../../../containers/BrandedCard/BrandedCard';
import mobilePhoneControl from '../../../controls/MobilePhoneControl';
import {sendVerificationCode} from '../../../../services/pimsClient';
import {ClientConfigContext} from "../../../../clientContext";
import {BrandConfig, ClientConfigType} from "../../../../../types/clientConfigTypes";
import { sendEventByEventObject, sendEventbyIdVerification} from '../../../../services/amplitudeClient';
import { AmplitudeEventType } from '../../../../../types/clientConfigTypes';
import { LocationState } from '../../../../../types/reactTypes';
import withLogger, { ComponentLoggerProps } from '../../../HOC/WithLogger';

export type CollectPhoneNumberFormProps = {
    brandInfo: BrandConfig,
    header: string
}

function CollectPhoneNumberForm({brandInfo, header, log}: CollectPhoneNumberFormProps & ComponentLoggerProps) {
    const {instance, accounts} = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);

    const history = useHistory();
    const {handleSubmit, errors, control, formState} = useForm({mode: 'onChange'});
    const [jwt, setJwt] = useState("");
    const location = useLocation<LocationState>();

    useEffect(() =>{
        const eventPropsData  = {
            "event_type": AmplitudeEventType.IdentityVerification_PhoneEntry,
            "event_properties": {
                "identity_verification": 'sms',
                "title_text": header
            }
        }
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt)=>{
            setJwt(jwt);
             sendEventByEventObject(jwt, eventPropsData);
        });
    },[])
    const onSubmit = async (data: any) => {
        sendEventbyIdVerification(jwt, AmplitudeEventType.IdentityVerification_PhoneEntry_Request, 'sms')
        const numberNoDashes = data.cellPhoneNumber.replace(/[-]/g, "");
        try {
            const response = await sendVerificationCode({
                jwt,
                value: numberNoDashes,
                pimsApiUrl: clientConfiguration.pimsApiUrl,
                language: i18n.languages[0]
            });
            if (response.ok) {
                history.push({
                    pathname: "/verification-code",
                    state: {phoneNumber: data.cellPhoneNumber, isCrossRegion: location?.state?.isCrossRegion}
                });
            } else {
                if (clientConfiguration.bypassProve) {
                    history.push({
                        pathname: "/verification-code",
                        state: {phoneNumber: data.cellPhoneNumber, isCrossRegion: location?.state?.isCrossRegion}
                    });
                } else {
                    // TODO: Better alternative, stay on this page and allow user to re-send the code?
                    log.error(`Sent verification code fail. Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                    history.push("/error");
                }
            }
        } catch (err: any) {
            log.error(`Error occurred - ${err.message}`);
            history.push("/error");
        }
    };

    const {isDirty, isValid} = formState;
    const {t, i18n} = useTranslation();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BrandedCard brand={brandInfo}>
                    {{
                        header: (<>
                            <Typography variant="h2">
                                {t(header)}
                            </Typography>
                        </>),
                        content: (
                            <>
                                <Grid container justify="center" item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                            {t("To verify your identity, we will send you an SMS text message with a code to your cell phone.")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        &nbsp;
                                    </Grid>
                                    <Grid item xs={12}>
                                        {mobilePhoneControl(control, errors, "Cell phone number", undefined)}
                                    </Grid>
                                </Grid>
                            </>),
                        actions: (
                            <>
                                <Grid container justify="center" item xs={12}>
                                    <Grid container justify="center" item xs={10}>
                                        <Button type="submit" disabled={!isDirty || !isValid} variant="contained">{t('Request Code')}</Button>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }}
                </BrandedCard>
            </form>
        </>
    );
}

export default withLogger(CollectPhoneNumberForm, { eventSource: "CollectPhoneNumberForm"});

