import {v4 as uuidv4} from 'uuid';
import {getDocumentCookie} from "@Praia-Health/spi-common-utils/dist/requestCookieUtils";

export const CORRELATION_ID_HEADER = "correlation-id";
export const QUERY_PARAMETER_PLATFORM = 'platform';
export const QUERY_PARAMETER_CLIENT = 'client_app';

// We want to add the query parameters 'device' and 'client' to the logs.
export function storeLogMetadata(): void {
    readQueryParameter(QUERY_PARAMETER_CLIENT);
    readQueryParameter(QUERY_PARAMETER_PLATFORM);
}

function readQueryParameter(key: string): void {
    const searchParams = new URLSearchParams(document.location.search);
    const queryParameter = searchParams.get(key);
    if (queryParameter) {
        window.sessionStorage.setItem(key, queryParameter);
    }
}

export function getLogMetadata(): string {
    const client = window.sessionStorage.getItem(QUERY_PARAMETER_CLIENT);
    const device = window.sessionStorage.getItem(QUERY_PARAMETER_PLATFORM);
    return `${QUERY_PARAMETER_CLIENT}=${client}&${QUERY_PARAMETER_PLATFORM}=${device}`;
}

export function setClientCorrelationId(correlationId: string): void {
    document.cookie = `correlationId=${correlationId}; Path=/;`;
}

// get from cookie or use uuidv4
export function getClientCorrelationId(): string {
    const correlationId = getDocumentCookie(document.cookie, CORRELATION_ID_HEADER)
    return correlationId || uuidv4();
}
