import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import { BrandProps, ClientConfigType } from "../../../../types/clientConfigTypes";
import Button from '../../elements/Button';
import { isRedirectUrlValid } from '../../../services/validation';
import { ClientConfigContext } from '../../../clientContext';
import Icon from '../../elements/Icon';
import {OPENID_PARAMS} from "../../../../types/openid_enum";
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';

function MfaSetupSuccessPage({ brandInfo, log }: BrandProps & ComponentLoggerProps) {
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const history = useHistory();
    const search = document.location.search;
    const searchParams = new URLSearchParams(search);
    const redirectUrl = searchParams.get(OPENID_PARAMS.REDIRECT_URI) || searchParams.get(OPENID_PARAMS.REDIRECT_URL) as string;

    const { t } = useTranslation();

    const onSubmit = async () => {
        if (redirectUrl) {
            const isValid = await isRedirectUrlValid(redirectUrl);
            log.info(`MfaSetupSuccessPage(): RedirectURL: ${redirectUrl}, IsValid: ${isValid}`);
            if (isValid) {
                window.location.href = redirectUrl;
            } else {
                log.error(`MfaSetupSuccessPage(): Invalid redirect URL for MFA Email setup flow`);
                history.push("/error");
            }
        }
    }

    return (
        <>
            <div>
                <form onSubmit={onSubmit} className={"displayFlex"}>
                    <BrandedCard brand={brandInfo}>
                        {{
                            header: (<>
                                <Grid item container spacing={3} >
                                    <Grid item xs={12}>
                                        <Icon size={63.74} iconUri={`${clientConfiguration.cdnPath}${clientConfiguration.mfaConfig.securityIconUri}`} altText={""} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            {t("Success!")}
                                        </Typography>
                                        <Typography variant="body1" align="left" className='mt-1'>
                                            {t("You have successfully enabled your two-step verification for this account.")}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </>),
                            content: (<>
                            </>),
                            actions: (<>
                                <Grid container justify="center" item xs={12} className="mb-4">
                                    <Grid container justify="center" item xs={12}>
                                        <Button type="submit" variant="contained" widthOverride="200px">{t('GO TO MYCHART')}</Button>
                                    </Grid>
                                </Grid>
                            </>)
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );
}

export default withLogger(MfaSetupSuccessPage, { eventSource: "MfaSetupSuccessPage" });
