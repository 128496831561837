import React, { useEffect } from 'react';
import BrandedCard, { BrandedCardActions, BrandedCardContent, BrandedCardHeader } from '../../containers/BrandedCard_v2';
import TypographyI18N from '../../elements/TypographyI18N';
import { useForm } from 'react-hook-form';
import EmailControl from '../../controls/EmailControl';
import { Box, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OPENID_PARAMS } from '../../../../types/openid_enum';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { AmplitudeEventType } from '../../../../types/clientConfigTypes';
import { useBrand } from "../../../hooks/useBrand";
import useConfig from '../../../hooks/useConfig';
import { GetEmailResponse } from '../../../../router/types';
import {useMsal} from "@azure/msal-react";
import { useErrorHistoryPush } from '../../../util/errorUtils';
import { getAmplitudeIdentifiers } from '../../../services/amplitudeClient';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';

const useStyles = makeStyles(() => ({
    emailContainer: {
        alignItems: "flex-end",
        display: "flex"
    },
    leftButton: {
        height: 47,
        width: 144
    },
    rightButton: {
        borderRadius: 24,
        width: 144
    }
}));

type AIAAnalyticsProperties = {
    activation_method?: string;
    activation_source?: string;
    link_expired?: string;
    epi_authorized?: string;
    non_patient_proxy?: string;
}

/**
 * State machine:
 * Loading => EmailOnFile => DifferentEmail
 * OR
 * Loading => DifferentEmail
 */
enum PageState {
    Loading,
    EmailOnFile,
    DifferentEmail
}

const AutoInstantActivationLandingPage = ({ log }: ComponentLoggerProps) => {
    const { instance } = useMsal();
    const searchParams = new URLSearchParams(window.location.search);
    const { errors, control, setValue, getValues } = useForm({ mode: 'onChange' });
    const [ pageState, setPageState ] = React.useState<PageState>(PageState.Loading);
    const [ defaultValue, setDefaultValue ] = React.useState<string>('');
    const [ isNonPatientProxy, setNonPatientProxy ] = React.useState<boolean>(false);
    const [ isExpired, setExpired ] = React.useState<boolean>(false);
    const classes = useStyles();
    const {t} = useTranslation();
    const { sendEvent, eventProperties, setEventProperties } = useAnalytics({ eventSource: '', useJWT: false });
    const ehrInstance = searchParams.get(OPENID_PARAMS.INSTANCE)?.toLowerCase() || '';
    const { brand } = useBrand(); // may throw error on bad brand name and no default is set.
    const errorHistoryPush = useErrorHistoryPush();

    const token = searchParams.get(OPENID_PARAMS.INST_ACT) || '';
    const autoInstantActivationMapping = useConfig<{[instance: string]: string}>('myChartConfig.autoInstantActivationMapping');

    const ehrSystem = autoInstantActivationMapping?.[ehrInstance] ?? '';
    const getEmailParams = new URLSearchParams({
        ehrSystem,
        token
    });

    const loadEmail = async () => {
        let aiaAnalyticsProperties: AIAAnalyticsProperties = {}
        try {
            searchParams.delete(OPENID_PARAMS.INST_ACT);
            log.info(`loadEmail(): using aia link: ${window.location.pathname}/${searchParams}`);
            const response = await fetch('/instantactivation/getEmail?' + getEmailParams.toString(), {
                method: 'GET',
            });
            const result: GetEmailResponse = await response.json();
            
            // On error, redirect to the error page
            if ('error' in result) {
                sendEventAndRedirectToErrorPage('getEmail() failed');
                return;
            }

            const { email, isTokenValid, isNonPatientProxy, analyticsProperties } = result;
            aiaAnalyticsProperties = { ...analyticsProperties, non_patient_proxy: String(isNonPatientProxy) };
            
            // If the AIA token is invalid (expired, probably), redirect the user to the appropriate page
            if (!isTokenValid) {
                // Patients with invalid/expired AIA codes should go to the Prove flow after capturing their email
                // Non-patient proxies with invalid/expired AIA codes should go to the error page
                setExpired(true);

                if (isNonPatientProxy) {
                    // Non-patient proxies with invalid/expired AIA codes should go to the error page
                    sendEventAndRedirectToErrorPage('Invalid or expired AIA code for a Non-Patient Proxy', aiaAnalyticsProperties);
                    return;
                }
            }
            // Send AIA event with analytics 
            sendEvent(AmplitudeEventType.SSOSignUp_AIA_ActivateAccount_Link, {
                ...eventProperties,
                ...aiaAnalyticsProperties
            });

            setNonPatientProxy(isNonPatientProxy); // Needed by the authorize page to direct non-patient proxies to the correct error page

            if (email) {
                sendEvent(AmplitudeEventType.SSOSignUp_Screen_AutofillEmail, {
                    activation_method: analyticsProperties.activation_method,
                    activation_source: analyticsProperties.activation_source
                });
                setDefaultValue(email);
                setPageState(PageState.EmailOnFile);
            } else {
                setPageState(PageState.DifferentEmail);
            }
            setEventProperties(aiaAnalyticsProperties);
        } catch (err: any) {
            sendEventAndRedirectToErrorPage(`loadEmail() failed. ${err.message}`);
        }
    }

    const sendEventAndRedirectToErrorPage = (errorMsg: string, aiaAnalyticsProperties?: any) => {
        sendEvent(AmplitudeEventType.SSOSignUp_AIA_ActivateAccount_Link, {
            ...eventProperties,
            ...aiaAnalyticsProperties
        });
        errorHistoryPush('/error', errorMsg);
    }

    useEffect(() => {
        // SPI-400. When the patient uses the AIA flow, they should be on a clean session with no previous user session
        instance.logoutRedirect({
            onRedirectNavigate: () => false
        }).finally(() => {
            loadEmail();
        });
    }, []);

    const handleNextClick = async () => {
        const email = getValues('email');

        if (pageState === PageState.EmailOnFile) {
            sendEvent(AmplitudeEventType.SSOSignUp_Screen_AutofillEmail_Confirm, {
                activation_method: eventProperties.activation_method,
                activation_source: eventProperties.activation_source
            });
        } else {
            sendEvent(AmplitudeEventType.SSOSignUp_Screen, {
                activation_method: eventProperties.activation_method,
                activation_source: eventProperties.activation_source,
                button_text: 'next'
            });
        }

        // Stitch the amplitude identifiers from the browser client through to the PIP server
        const { amplitudeDeviceId: deviceId, amplitudeSessionId: sessionId } = getAmplitudeIdentifiers();

        try {
            // [SPI-345]: stores the token in session and on cache for fallback.
            // POST /selectEmail will transform the token and store in a distributed cache.
            sessionStorage.setItem("aiaCode", token);
            const response = await fetch('/instantactivation/selectEmail', {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify({ brand, ehrSystem, email, token, isNonPatientProxy, isExpired, deviceId, sessionId })
            });
            const url = await response.json();

            window.location.replace(url);
        } catch (err: any) {
            errorHistoryPush('/error', `handleNextClick() failed. ${err.message}`);

        }
    }

    const handleCancelClick = () => {
        sendEvent(AmplitudeEventType.SSOSignUp_Screen, {
            activation_method: eventProperties.activation_method,
            activation_source: eventProperties.activation_source,
            button_text: 'cancel'
        });
        window.location.replace(`/?brand=${brand}`);
    }

    const handleUseDifferentEmailClick = () => {
        sendEvent(AmplitudeEventType.SSOSignUp_Screen_AutofillEmail_Update, {
            activation_method: eventProperties.activation_method,
            activation_source: eventProperties.activation_source
        });
        setValue('email', '');
        setPageState(PageState.DifferentEmail);
    }

    const currentValue = getValues('email');

    let isEmailDisabled, isRightButtonDisabled, leftButton, message, placeholder, rightButtonText, showLabel;
    if (pageState === PageState.EmailOnFile) {
        isEmailDisabled = true;
        isRightButtonDisabled = false;
        leftButton = <Button type="button" color="primary" className={classes.leftButton} onClick={handleUseDifferentEmailClick}>{t('use different email')}</Button>;
        message = <TypographyI18N>Do you want to use the email on file to create an account?</TypographyI18N>;
        rightButtonText = t('Yes');
        showLabel = true;
    } else if (pageState === PageState.DifferentEmail) {
        isEmailDisabled = false;
        isRightButtonDisabled = !currentValue || Object.keys(errors).length > 0;
        leftButton = <Button type="button" color="primary" className={classes.leftButton} onClick={handleCancelClick}>{t('Cancel')}</Button>;
        placeholder = 'Your new email';
        rightButtonText = t('Next');
        showLabel = false;
    }

    return (
        <BrandedCard maxWidth={450} minHeight={530} isLoading={pageState === PageState.Loading}>
            <BrandedCardHeader>
                <TypographyI18N variant='h2'>Welcome</TypographyI18N>
            </BrandedCardHeader>
            <BrandedCardContent>
                <Box height={56}>{message}</Box>
                <Box height={118} className={classes.emailContainer}>
                    <EmailControl
                        control={control}
                        defaultValue={defaultValue}
                        disabled={isEmailDisabled}
                        errors={errors}
                        label='Email'
                        placeholder={placeholder}
                        showLabel={showLabel}
                    />
                </Box>
            </BrandedCardContent>
            <BrandedCardActions>
                {leftButton}
                <Button
                    className={classes.rightButton}
                    color="secondary"
                    disabled={isRightButtonDisabled}
                    onClick={handleNextClick}
                    type="button"
                    variant="contained"
                >{rightButtonText}</Button>
            </BrandedCardActions>
        </BrandedCard>
    )
}

export default withLogger(AutoInstantActivationLandingPage, { eventSource: "AutoInstantActivationLandingPage" });
