import { Dialog, IconButton, DialogContent } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { BrandProps, ClientConfigType } from "../../../../types/clientConfigTypes"
import CloseIcon from '@material-ui/icons/Close';
import { redirectWithSoftLogout } from "../../../util/sessionTimeout";
import { useMsal } from "@azure/msal-react";
import { useLocation } from 'react-router-dom';
import { PromotionState } from "../../../../types/reactTypes";
import { AppPromImage } from "./AppPromImage";
import { AppPromotionControl } from "./AppPromotionControl";
import useDeviceWidth from "../../../hooks/useDeviceWidth";
import { appPromotionEvents, sendAppPromotionForAIA } from "../../../services/amplitudeClient";
import { ClientConfigContext } from "../../../clientContext";
import getAccessToken from "../../../util/getAccessToken";
import withLogger, { ComponentLoggerProps } from "../../HOC/WithLogger";

const AppPromotionComponent = ({ brandInfo, log }: BrandProps & ComponentLoggerProps) => {
    const location = useLocation<PromotionState>();
    const [jwt, setJwt] = useState(""); 
    const { isSmallWidth } = useDeviceWidth();
    const { instance, accounts } = useMsal();
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);

    const handleRedirect = async () => {
        await sendAppPromotionForAIA(jwt, appPromotionEvents.SKIP_APP_PROMOTION, brandInfo.brandName ?? brandInfo.brand);
        redirectWithSoftLogout(instance, location.state?.redirectUrl);
    }

    useEffect(() => {
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) => {
            setJwt(jwt);
            sendAppPromotionForAIA(jwt, appPromotionEvents.VIEW_APP_PROMOTION, brandInfo.brandName ?? brandInfo.brand);
        });
    }, []);

    return (
        <Dialog className="PromotionDialog" open={true}>
            <IconButton className={`closeButton ${isSmallWidth ? "hidden": ""}`}
                aria-label="close"
                onClick={handleRedirect}>
                <CloseIcon fontSize="large" htmlColor={brandInfo.promotion?.brandColor} />
            </IconButton>
            <DialogContent>
                <div className="promotionContainer">
                    <AppPromImage brandInfo={brandInfo} />
                    <AppPromotionControl brandInfo={brandInfo} />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export const AppPromotion = withLogger(AppPromotionComponent, { eventSource: "AppPromotion"});

