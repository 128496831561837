import React, {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import './App.css';
import '../../style.css'
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication} from "@azure/msal-react";
import {InteractionType, RedirectRequest} from "@azure/msal-browser";
import {ClientConfigContext} from "../clientContext";
import {getClientCorrelationId} from '../logger/logMetadata'
import {ClientConfigType} from "../../types/clientConfigTypes";
import {OPENID_PARAMS} from "../../types/openid_enum";
import {
    manageAuthOnStateChange,
    initializeSessionTimeout,
    isNotMobile
} from "../util/sessionTimeout";
import AuthenticatedRouter from './routers/AuthenticatedRouter';
import UnauthenticatedRouter from './routers/UnauthenticatedRouter';
import { useBrand } from '../hooks/useBrand';

const UNAUTHENTICATED_PAGES = [ "/error", "/username-recovery", "/logout", "/instantactivation" ];

function App(): JSX.Element {
    const location = useLocation();
    const { brand, cssbranding } = useBrand();

    const searchParams = new URLSearchParams(document.location.search);
    const language = searchParams.get(OPENID_PARAMS.LANG) || 'en';
    const ehrSystem = searchParams.get(OPENID_PARAMS.EHR_SYSTEM) as string;
    const cancelRedirectUrl = searchParams.get(OPENID_PARAMS.INCOMING_MYCHART_URL) || searchParams.get(OPENID_PARAMS.CANCEL_REDIRECT_URI) || '';
    const correlationId = getClientCorrelationId();

    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);
    const mfaEnabled = searchParams.get(OPENID_PARAMS.DISPLAY_MFA_FLOWS) || "false";
    const request: RedirectRequest = {
        scopes: clientConfiguration.b2cTenantConfig.scopes,
        correlationId: correlationId,
        extraQueryParameters: {
            brand,
            lang: language,
            [OPENID_PARAMS.CANCEL_REDIRECT_URI]: cancelRedirectUrl,
            [OPENID_PARAMS.DISPLAY_MFA_FLOWS]: mfaEnabled,
            [OPENID_PARAMS.CORRELATION_ID]: correlationId,
        },
    };
    if (request.extraQueryParameters && ehrSystem) {
        request.extraQueryParameters[OPENID_PARAMS.EHR_SYSTEM] = ehrSystem;
    }
    if (request.extraQueryParameters) {
        request.extraQueryParameters.cssbranding = cssbranding;
    }

    // If we're not on an unauthenticated page, then authentication is required.
    const requireAuthentication = !UNAUTHENTICATED_PAGES.includes(location.pathname);

    // On React app startup, on authenticated pages, it performs a silent login to the IDP if there are no local session.
    // If a local session exists, then no calls to the IDP are made.
    /**
     * See sessionTimeout.ts for the steps involved in authenticating PIP.
     */
    const msalAuthentication = requireAuthentication ? useMsalAuthentication(InteractionType.Silent, request) : null;
    const result = msalAuthentication?.result ?? null;
    const {instance, accounts, inProgress} = useMsal();

    useEffect(() => {
        if (msalAuthentication?.error && requireAuthentication) {
            msalAuthentication.login(InteractionType.Redirect, request);
        }
    }, [msalAuthentication?.error]);

    useEffect(() => {
        manageAuthOnStateChange(instance, accounts, result, inProgress, clientConfiguration.userActivityLimitMs);
    }, [msalAuthentication?.result, accounts, inProgress]);

    /**
     * [SPI-3914] Prevent the logout timer functionality in mobile workflow. Mobile App can leave a hidden view of PIP
     * in the app and that would eventually cause an undesired hard logout.
     */
    if (requireAuthentication && isNotMobile(searchParams)) {
        initializeSessionTimeout(instance, clientConfiguration.userActivityLimitMs);
    }

    if (UNAUTHENTICATED_PAGES.includes(location.pathname)) {
        return (
            <>
                <AuthenticatedTemplate>
                    <UnauthenticatedRouter />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <UnauthenticatedRouter />
                </UnauthenticatedTemplate>
            </>
        );
    } else {
        return (
            <AuthenticatedTemplate>
                <AuthenticatedRouter />
            </AuthenticatedTemplate>
        );
    }
}

export default App;
