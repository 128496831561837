import React, { useContext } from 'react';
import { ClientConfigContext } from '../../clientContext';
import { ClientConfigType } from '../../../types/clientConfigTypes';
import { ApiRequestLogger } from '../../logger/apiRequestLogger';

export interface componentOptions {
  eventSource?: string
}

export interface ComponentLoggerProps {
  log: ApiRequestLogger
}

const withLogger = <P extends object>(WrappedComponent: React.ComponentType<P>, options: componentOptions) => {

  const componentWithLogger = (props : any) => {
    const clientConfig: ClientConfigType = useContext(ClientConfigContext);
    
    const maxMessageLength= clientConfig?.loggingConfig?.maxMessageLength;
    const { eventSource } = options;
    const logger = new ApiRequestLogger({ eventSource: eventSource }, maxMessageLength);
    
    return <WrappedComponent {...props} log={logger} />;
  };

  return componentWithLogger;
};

export default withLogger;
